import GLightbox from '../vendors/glightbox'

// Only load GLightbox if it's needed on the page
const glightboxSelector = 'js-glightbox',
    playVideoLinkSelector = 'js-play-video';

if (document.getElementsByClassName(glightboxSelector).length) {
    // glightboxScript is defined in the footer include. It uses the revisioned path from the rev-manifest
    loadJS(glightboxScript, function () {
        const glightbox = GLightbox({
            selector: glightboxSelector,
            moreLength: 0
        });

        // Play video when opening video links
        let videoLinks = document.getElementsByClassName(playVideoLinkSelector);
        [...videoLinks].forEach((node) => node.addEventListener('click', (e) => glightbox.open(document.getElementById('video_' + e.target.dataset.videoId))));
    });
}

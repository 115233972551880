let showhideTrigger = document.querySelectorAll('.js-showhide__trigger');

function showhideToggle() {
    let showhideContainer = this.closest('.js-showhide');
    showhideContainer.classList.toggle('js-showhide--active');

    if (showhideContainer.classList.contains('js-showhide--active')) {
        this.style.display = 'none';
    } else {
        showhideContainer.querySelector('.js-showhide__trigger').style.display = 'initial';
    }

    if (typeof initSwiperCardCarousel === "function") {
        initSwiperCardCarousel();
    }

}

showhideTrigger.forEach(item => item.addEventListener('click', showhideToggle));

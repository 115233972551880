const scrollTo = document.querySelectorAll(".js-scroll-to");

scrollTo.forEach(item =>
  item.addEventListener("click", function() {
    // get the data-scroll name of clicked item
    const scrollSection = item.dataset.scroller;
    // pass this into the where to scroll
    document.querySelector(`.${scrollSection}`).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start"
    });
  }),
);

/*
  Example
    clicked item:
    <a class="js-scroll-to" data-scroller="js-play-with">
      Btn
    </a>
    scrolls to:
    <div class="js-play-with"></div>
*/


const jsScrollTop = document.querySelectorAll('.js-scroll-top');

jsScrollTop.forEach(item =>
    item.addEventListener("click", function() {
        // get the data-scroller name of clicked item
        const scrollSection = item.dataset.scroller;
        
        document.querySelectorAll('.' + scrollSection).forEach(item =>
            item.scrollTo(0, 0)
        );
    }),
);

/*
  Example
    clicked item:
    <a class="js-scroll-top" data-scroller="nav-list-sub">
      Btn
    </a>
*/
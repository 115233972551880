let accordionTrigger = document.querySelectorAll('.js-accordion__trigger');

// ie fix
accordionTrigger = [].slice.call(accordionTrigger);

function toggleAccordion() {
    let accordionSection = this.closest('.js-accordion');
    accordionSection.classList.toggle('js-accordion--active');
}

accordionTrigger.forEach(item => item.addEventListener('click', toggleAccordion));
